hr {
  height: 1px;
  background-color: #4e7aea;
  border: 0;
}

a {
  text-decoration: unset;
}

.hr-error {
  height: 1px;
  background-color: grey;
  border: 0;
}

body {
  //max-width: 2600px;
  margin: 0;
}

/* @media (max-width: 1440px) {
  body {
    overflow-x: auto;
    width: 1440px;
  }
} */

@media (max-width: 800px) {
  body {
    overflow-x: auto;
    width: auto;
  }
}
