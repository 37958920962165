.sidebar-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;

  &:hover {
    font-weight: 700;
  }

  &:active {
    font-weight: 700;
    color: #fff;
  }

  & > * > #typo {
    color: #fff;
  }
}
